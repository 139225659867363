<script>
export default {
  created() {
    document.body.classList.add("auth-body-bg");
    window.history.pushState(null, null, window.location.href);
    
    // Add a popstate listener to handle browser back button
    window.addEventListener('popstate', this.preventBackNavigation);
  },
  beforeRouteLeave(to, from, next) {
    // Prevent navigating away from the page
    next(false);
    
    // Redirect back to the same page
    this.$router.replace({ name: this.$route.name });
  },
  methods: {
    preventBackNavigation() {
      // Redirect to the same route
      this.$router.replace({ name: this.$route.name });
    },
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener('popstate', this.preventBackNavigation);
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
              style="max-width: 548px; margin: auto"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <img
                          src="@/assets/images/success.png"
                          alt="sucess-image"
                        />
                        <h4 class="font-size-18 mt-4">
                          Your Password Updated successfully
                        </h4>
                      </div>
                      <div class="mt-5 text-center">
                        <p>© 2022 B-SEEN</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>